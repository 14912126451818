<template>
  <v-row wrap no-gutters>
    <credentials-select
      :key="`${step.id}-credentialname`"
      step-type="PAYMENT_SENSE_PAC"
      :properties="properties"
      step-label="paymentSensePac"
      :readonly="!canEdit"
      :required="false"
      :input-has-to-be-validated="true"
      @change="handleChange('credentialName', $event)"
    />

    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedPaymentSensePacActions"
        :default-value="properties.action"
        step-label="paymentSensePac"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>

    <v-col v-if="properties.action === 'START_REPORT'" cols="12">
      <v-select
        :key="`${step.id}-reportType`"
        v-model="properties.parameters.reportType"
        :items="formattedPaymentSensePacReportTypes"
        :label="$lang.labels.reportType"
        outlined
        dense
        required
        data-cy="paymentSensePac-parameters-reportType"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
      ></v-select>
    </v-col>

    <v-col v-if="properties.action === 'SIGNATURE'" cols="12">
      <v-checkbox
        :key="`${step.id}-accepted`"
        v-model="properties.parameters.accepted"
        dense
        data-cy="paymentSensePac-parameters-accepted"
        :label="$lang.labels.accepted"
      />
    </v-col>

    <v-col
      v-if="!['START_REPORT', 'GET_TERMINALS', 'GET_TERMINAL'].includes(properties.action)"
      cols="12"
    >
      <v-text-field
        :key="`${step.id}-requestId`"
        v-model="properties.parameters.requestId"
        outlined
        dense
        data-cy="paymentSensePac-parameters-requestId"
        :label="$lang.labels.requestId"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
      />
    </v-col>

    <v-col v-if="properties.action !== 'GET_TERMINALS'" cols="12">
      <v-text-field
        :key="`${step.id}-terminalId`"
        v-model="properties.parameters.terminalId"
        outlined
        dense
        data-cy="paymentSensePac-parameters-terminalId"
        :label="$lang.labels.terminalId"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
      />
    </v-col>

    <v-col v-if="properties.action === 'START_TRANSACTION'" cols="12">
      <v-row no-gutters class="pl-4">
        <h4>{{ $lang.labels.transaction }}</h4>
        <v-col cols="12">
          <v-text-field
            :key="`${step.id}-amount`"
            v-model="properties.parameters.transaction.amount"
            outlined
            dense
            data-cy="paymentSensePac-parameters-transaction-amount"
            :label="$lang.labels.amount"
            :readonly="!canEdit"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            :key="`${step.id}-amountCashback`"
            v-model="properties.parameters.transaction.amountCashback"
            outlined
            dense
            data-cy="paymentSensePac-parameters-transaction-amountCashback"
            :label="$lang.labels.amountCashback"
            :readonly="!canEdit"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            :key="`${step.id}-amountPreAuthorisedTotal`"
            v-model="properties.parameters.transaction.amountPreAuthorisedTotal"
            outlined
            dense
            data-cy="paymentSensePac-parameters-transaction-amountPreAuthorisedTotal"
            :label="$lang.labels.amountPreAuthorisedTotal"
            :readonly="!canEdit"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            :key="`${step.id}-authCode`"
            v-model="properties.parameters.transaction.authCode"
            outlined
            dense
            data-cy="paymentSensePac-parameters-transaction-authCode"
            :label="$lang.labels.authCode"
            :readonly="!canEdit"
          />
        </v-col>
        <v-col cols="12">
          <v-checkbox
            :key="`${step.id}-cardholderNotPresent`"
            v-model="properties.parameters.transaction.cardholderNotPresent"
            dense
            data-cy="paymentSensePac-parameters-transaction-cardholderNotPresent"
            :label="$lang.labels.cardholderNotPresent"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            :key="`${step.id}-currency`"
            v-model="properties.parameters.transaction.currency"
            :items="PAYMENT_SENSE_CURRENCIES"
            :label="$lang.labels.currency"
            outlined
            dense
            required
            data-cy="paymentSensePac-parameters-transaction-currency"
            :readonly="!canEdit"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            :key="`${step.id}-transactionType`"
            v-model="properties.parameters.transaction.transactionType"
            :items="formattedPaymentSensePacTransactionTypes"
            :label="$lang.labels.transactionType"
            outlined
            dense
            required
            data-cy="paymentSensePac-parameters-transaction-transactionType"
            :readonly="!canEdit"
          />
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        class="required-asterisk"
        data-cy="paymentSensePac-targetObject"
        :label="$lang.labels.targetObject"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
      />
    </v-col>
  </v-row>
</template>

<script>
import { ActionSelect, CredentialsSelect } from './components'
import StepMixin from './StepMixin'
import {
  PAYMENT_SENSE_PAC_ACTIONS,
  PAYMENT_SENSE_PAC_REPORT_TYPES,
  PAYMENT_SENSE_CURRENCIES,
  PAYMENT_SENSE_PAC_TRANSACTION_TYPES
} from './constants'
import { getNestedPropertyForSetting, castValueForStep } from '@/utils/helpers'

export default {
  name: 'PaymentSensePacStep',
  components: {
    ActionSelect,
    CredentialsSelect
  },
  mixins: [StepMixin],
  data() {
    return {
      PAYMENT_SENSE_CURRENCIES
    }
  },
  computed: {
    formattedPaymentSensePacActions() {
      return PAYMENT_SENSE_PAC_ACTIONS.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedPaymentSensePacReportTypes() {
      return PAYMENT_SENSE_PAC_REPORT_TYPES.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedPaymentSensePacTransactionTypes() {
      return PAYMENT_SENSE_PAC_TRANSACTION_TYPES.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  created() {
    if (!this.properties.parameters) this.properties.parameters = {
      requestId: '',
      terminalId: ''
    }
  },
  methods: {
    onHandleChange(key, val) {
      // Typecasting for specific fields
      const value = castValueForStep(
        key,
        val,
        {
          shouldBeString: ['parameters.requestId', 'parameters.terminalId']
        }
      )

      this.handleChange(key, castedValue)
    },
    paymentSensePacActionChanged(newVal) {
      switch (newVal) {
      case 'CANCEL_TRANSACTION':
      case 'GET_TRANSACTION':
      case 'GET_REPORT':
        this.properties.parameters = {
          requestId: '',
          terminalId: ''
        }
        break
      case 'GET_TERMINAL':
        this.properties.parameters = {
          terminalId: ''
        }
        break
      case 'START_TRANSACTION':
        this.properties.parameters = {
          requestId: '',
          terminalId: '',
          transaction: {
            amount: '',
            amountCashback: '',
            amountPreAuthorisedTotal: '',
            authCode: '',
            cardholderNotPresent: false,
            currency: '',
            transactionType: ''
          }
        }
        break
      case 'SIGNATURE':
        this.properties.parameters = {
          accepted: '',
          requestId: '',
          terminalId: ''
        }
        break
      case 'START_REPORT':
        this.properties.parameters = {
          reportType: '',
          terminalId: ''
        }
        break
      case 'GET_TERMINALS':
        this.properties.parameters = {}
        break
      default:
        this.properties.parameters = {
          requestId: '',
          terminalId: ''
        }
      }
    }
  }
}
</script>
